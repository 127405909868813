.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.inventory-info {
  box-shadow: rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px;
  pointer-events: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  inset: 50px 0px 0px auto;
  width: 100%;
  max-width: 100vw;
  animation-name: css-0, css-8;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both;
  background: rgb(255, 255, 255);
  margin-left: 15px;
  padding: 10px 20px 20px 20px;
  width: 70vw;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
  z-index: 100px;
  overflow-y: auto;
  overflow-x: auto;
}

.right-visualization {
  border-left: 1px solid #bfbfbf;
  padding-left: 20px;
}


.timeout-modal {
  height: 90vh;
  display: flex;
  align-items: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(13px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#content {
  margin-top: 75px;
  /* Adjust the value based on your top navigation bar's height */
}

.admin-center {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  height: calc(-50px + 100vh);
  flex-direction: row;
  flex-grow: 1;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  color: rgb(50, 49, 48);
  height: 100%;
  overflow: unset;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
  padding: 0px 1rem 1.5rem 1rem;
  height: calc(100vh - 3rem);
}

.child-container {
  border-radius: 15px;
  background-color: white;
  display: flex;
  flex-grow: 1;
}

@media (max-width: 767px) {
  .operations-chip-container {
    flex-direction: column;
  }
}

.lbl-margin-top {
  margin-top: 1.25rem !important;
}

.panel-open {
  transition: margin-left 0.3s ease;
  /* Smooth transition for the margin change */
  margin-left: 198px;
}

.panel-closed {
  transition: margin-left 0.3s ease;
  /* Smooth transition for the margin change */
}

.main-content {
  flex-direction: row;
  flex-grow: 1;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  color: rgb(50, 49, 48);
  height: 100%;
}

.link {
  color: #0078d4;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.dashed-underline {
  text-decoration: underline;
  text-decoration-style: dashed;
}

.breadcrumb {
  margin-bottom: 5px !important;
}

hr {
  margin-top: 8px !important;
  margin-bottom: 0px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.error-red {
  color: red;
}

.action-btn {
  color: #1976d2
}

.success-green {
  color: green;
}

.bg-light-green {
  background-color: #e8fbe8;
}

.bg-light-red {
  background-color: #fbe8e8;
}

.label {
  font-size: 13px !important;
  color: rgba(0, 0, 0, 0.6);
}

.fw-500 {
  font-weight: 500 !important;
}

.entity-value {
  font-size: 14px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.delta-success {
  background: #007a4d;
  padding: 10px 15px;
  color: #ffffff;
  border-radius: 5px;
}

.delta-error {
  background: #d31510;
  padding: 10px 15px;
  color: #ffffff;
  border-radius: 5px;
}

.select-option {
  padding: 5px 8px;
  cursor: pointer;
}

.active-item {
  background-color: #c4c4c4 !important;
  padding: 5px 8px;
  cursor: pointer;
}

.item-disabled {
  pointer-events: none;
  opacity: 0.6;
}

.circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;

  &.blue {
    background-color: blue;
  }

  &.green {
    background-color: green;
  }

  &.red {
    background-color: red;
  }
}

.divider {
  border-left: 2px solid gray;
  padding-left: 10px;
}

.account-card {
  padding: 10px;
  border: 1px solid #ccc;
  display: flex;
  background: linear-gradient(to left, #5a94e6, #c4d5f4);
}

.shorthand-first-circle {
  width: 25px;
  height: 25px;
  background-color: #f4b874;
  /* Example background color */
  border-radius: 50%;
  justify-content: center;
  color: #de751b;
}

.shorthand-second-circle {
  width: 25px;
  height: 25px;
  background-color: #f485ea;
  /* Example background color */
  border-radius: 50%;
  justify-content: center;
}

.align-btn-tabs {
  padding-bottom: 5px;
  margin-top: -30px;
}

.top-nav-container header div {
  background-color: #F1F2F3 !important;
}

.bg-raya {
  background-color: #F1F2F3 !important;
}

.list-group-item {
  background-color: #F1F2F3 !important;
  padding: 10px 18px !important;

  &.submenu-item {
    padding: 5px 10px !important;
  }
}

.bg-transparent {
  background-color: transparent !important;
}

.bl-opening {
  border-left: 6px solid #1d72c6;

}

.bl-closing {
  border-left: 6px solid #7ab301;
}

.bl-inward {
  border-left: 6px solid #b4009e;
}

.bl-outward {
  border-left: 6px solid #fc8c01;
}

.logo {
  width: 70px;
  height: 35px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: 'pointer'
}

.adj-container {
  overflow: auto;
  max-height: calc(100vh - 6rem);
}

.tab-container {
  overflow: auto;
  max-height: calc(100vh - 20rem);
}

.inventory-container {
  overflow: auto;
  max-height: calc(100vh - 22rem);
}

.tab-container::-webkit-scrollbar,
.adj-container::-webkit-scrollbar,
.inventory-container::-webkit-scrollbar {
  background: transparent;
  width: 0.5rem !important;
  height: 0.5rem !important;
}

/* Track */
.tab-container::-webkit-scrollbar-track,
.adj-container::-webkit-scrollbar-track,
.inventory-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.tab-container::-webkit-scrollbar-thumb,
.adj-container::-webkit-scrollbar-thumb,
.inventory-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.5rem;
  height: 20px;
}

/* Handle on hover */
.tab-container::-webkit-scrollbar-thumb:hover,
.adj-container::-webkit-scrollbar-thumb:hover,
.inventory-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.navbar-expand-lg .navbar-toggler {
  display: block !important;
}

.sales-person-avatar {
  position: absolute !important;
  top: 21px;
  right: 30px;
  height: 30px !important;
  width: 30px !important;
}

.mt-chart {
  margin-top: -20px;
}

.text-capitalize {
  text-transform: capitalize;
}

.stepper-icon {
  height: 20px !important;
  width: 20px !important;
}

.edit-wo-icon {
  height: 28px !important;
  width: 28px !important;
  cursor: pointer;
  /* border: 1px solid #c4c4c4; */
  padding: 1px;
  border-radius: 5px;
  color: darkblue;
}

.edit-wo-icon {
  position: absolute !important;
  top: 21px;
  right: 53px;
  height: 30px !important;
  width: 30px !important;
}

.assigned-row {
  background-color: #DCDCDC;
}

.vertical-align-top {
  vertical-align: top !important;
}