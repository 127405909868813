.top-nav-bar {
    z-index: 100; /* Set a higher z-index than the side nav bar */
    height: 7vh !important; /* Set a fixed height for the top navigation bar */
    line-height: 40px; /* Ensure vertical centering of content */
    width: 100%;
    display: flex;
    border-spacing: 0;
    white-space: nowrap;
    flex: 0 1 auto;
    background-position: left top;
    margin-top:0px !important;
}

.index {
    z-index: inherit;
}

.notification-card {
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
}

.top-nav-bar {
  box-shadow: 0px 4px 6px -1px rgba(0,0,0,0.1), 0px 2px 4px -1px rgba(0,0,0,0.06);
}

.top-btn {
  margin-left: 10px;
  border: none;
  padding: 5px 10px;
  border-radius: 50%;
  display: block;
  align-items: center;
  justify-content: center;
  position: relative;
    margin: 0;
    padding: 0;
    min-width: 48px;
    text-align: center;
    cursor: pointer;
    outline-offset: -1px;
}

.nav-hidden {
  display: flex;
}


.dropdown-menu-custom {
  position: absolute;
  left: 'auto';
  inset: '0px auto auto -70px';
  right: 0; /* Align to the right of the toggle button */
  transform: translateX(-100%); /* Move the dropdown to the left */
}

.professional-card {
  width: 250px; /* Increase width */
  height: 300px; /* Increase height */
  font-size: 1rem; /* Increase font size */
  line-height: 1.5; /* Increase line height */
}

.professional-card .card-title-custom {
  font-size: 1.2rem; /* Increase title font size */
  font-weight: bold; /* Make title bold */
}

.professional-card .card-text-custom {
  font-size: 0.9rem; /* Decrease text font size for a more compact look */
}

.professional-card .link {
  margin-top: 0.5rem; /* Add space above the links */
}

.professional-card p {
  margin-bottom: 0.5rem; /* Reduce space between paragraphs */
}

  .link-container {
    display: flex;
    justify-content: space-between; /* This will place one item on the left and the other on the right */
    align-items: center; /* This will vertically align items if they have different heights */
    padding: 0.5rem 0; /* Add some padding for better spacing */
  }  

.brand-name {
    font-weight: normal;
    color: #333; /* Adjust the color to match your brand's color scheme */
}

.customer-name {
    font-weight: bold;
    color: #333; 
}

@media screen and (max-width: 767px) {
  .customer-name{
    display: none;
  }
}

  