.MuiTableCell-body {
    padding: 3px !important;
}

.MuiTableHead-root {
    border-top: 1px solid #e0e0e0;
}

.MuiFormControl-root {
    display: flex !important;
    flex-grow: 1 !important;
}

.MuiPaper-root {
    content: none !important;
}

.MuiInputLabel-root {
    transform: translate(12px, 9.5px) scale(1) !important;
    font-size: 13px !important;
}

input::placeholder {
    font-size: 13px;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(13px, -8px) scale(0.85) !important;
}

.MuiInputBase-root {
    input {
        height: 0.5rem !important;
    }
}

.adjustment-order-grid {

    .MuiTableHead-root,
    .MuiTableCell-root {
        border: none !important;
        box-shadow: none !important;
    }
}

.MuiDialogTitle-root {
    height: 60px;
}

.itemTransaction {
    .MuiInputBase-root {
        input {
            height: 0.2rem !important;
            font-size: 13px;
        }
    }
}

.Mui-disabled {
    -webkit-text-fill-color: #818589 !important;
}

.MuiTimelineItem-root::before {
    content: "";
    -webkit-flex: none !important;
    -ms-flex: 1;
    flex: none !important;
    padding: 0px;
}