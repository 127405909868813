.unauthenticated-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #0078d4 0%, #00bcf2 100%);
}
  
  .unauthenticated-content {
    padding: 3rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    animation: fadeInUp 0.5s ease-out;
    text-align: center;
  }
  
  .unauthenticated-content h1 {
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
    color: #333;
    animation: fadeInDown 1s ease-out;
  }
  
  .unauthenticated-content p {
    margin-bottom: 2.5rem;
    font-size: 1.1rem;
    color: #555;
    animation: fadeInDown 1s ease-out;
  }
  
  .unauthenticated-content .btn {
    padding: 0.75rem 2rem;
    font-size: 1rem;
    border-radius: 50px;
    background: #007bff;
    color: white;
    transition: background 0.3s ease;
  }
  
  .unauthenticated-content .btn:hover {
    background: #0056b3;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeInUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes fadeInDown {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  