.nav-bar {
    z-index: 99;
}

/* CSS for adjusting the sidebar based on zoom level (approximate) */
@media screen and (-webkit-min-device-pixel-ratio: 1.5), /* Webkit-based browsers */
       (min--moz-device-pixel-ratio: 1.5), /* Firefox */
       (min-resolution: 144dpi), /* Standard displays */
       (min-resolution: 1.5dppx) { /* Retina displays */
  .sidebar {
    width: 60px; /* Adjust the width for the collapsed sidebar */
  }
  .navbar-toggler {
    display: block !important; /* Show the toggle button */
  }
  .sidebar-expanded {
    width: 60px; /* Width of the expanded sidebar */
  }
}

.selected {
  background-color: gray; /* Change this to your preferred highlight color */
  color: #fff; /* Change this to your preferred text color */
}

body {
    background-color: #fbfbfb;
  }
  
  @media (min-width: 992px) {
    .sidebar {
      height: unset!important;
    }
  }
  
  /* Sidebar */
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding-top: 3.5rem;
    /* Height of navbar */
    /* box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%); */
    width: 200px;
    color: #fff;
    z-index: 99;
    }
  
  .sidebar.show {
    height: 100vh;
  }
  
  .sidebar .active {
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  }
  
  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */
  }

  /* Custom styles for the dropdown menu */
.custom-dropdown-menu {
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

/* Custom styles for the dropdown menu items */
.custom-dropdown-item {
  color: #333;
  font-weight: bold;
}

.custom-dropdown-item:hover {
  background-color: #ddd;
  color: #000;
}

/* Add styles for the toggle button */
.toggle-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: #555;
}

/* Adjust the submenu styles */
.submenu {
    color: #fff;
    padding: 10px;
}

.submenu .list-group-item {
    background-color: transparent;
    color: #555;
    padding: 5px 15px;
    border: none; /* Remove borders */
}

.submenu .list-group-item:hover {
    background-color: lightgray;
}

/* Adjust the styles for the selected area */
.selected-indicator {
    position: absolute;
    top: 0;
    left: 4px;
    height: 100%;
    width: 5px; /* Adjust the width of the indicator as needed */
    background-color: #3b71ca; /* Change this color to your preferred highlight color */
    border-radius: 0 4px 4px 0; /* Adjust the border radius to match the menu item */
}

/* Adjust the styles for the menu items */
.menu-item {
    position: relative;
    overflow: hidden;
}

.menu-item.selected .selected-indicator {
    display: block;
}

.menu-item .selected-indicator {
    display: none;
}
